<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Thêm tiếng thơ</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminPoemList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách tiếng thơ
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên bài thơ (*)</label>
              <input v-model="poem.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Lời bài thơ <i class="far fa-question-circle"
                                                                       :uk-tooltip="'Chỉ ghi lời tiếng thơ, không ghi tên tiếng thơ, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="poem.content"/>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Sáng tác <i class="far fa-question-circle"
                                                                  :uk-tooltip="'Chọn tác giả nhạc trong danh sách'"></i></label>
              <multiselect
                  v-model="poets"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchPoets"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Trình bày <i class="far fa-question-circle"
                                                        :uk-tooltip="'Chọn người trình bày trong danh sách'"></i></label>
              <multiselect
                  v-model="artists"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label for="year" class="font-semibold">Năm sáng tác</label>
              <input v-model="poem.year" class="shadow-none with-border" id="year" type="text" autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="poem.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="subtitle" class="font-semibold">Ghi chú</label>
              <input v-model="poem.subtitle" class="shadow-none with-border" id="subtitle" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-on:uploadDone="handleUploadDone" :musicTypeProp="musicType" :playerOptionsProp="playerOptions"
                    v-model:objectIdProp="poem.file_id"/>
        </div>

        <div class="mt-4" v-if="poem.file && poem.file.type === 'video'">
          <label class="font-semibold">Kiểu hiển thị nhạc</label>
          <select v-model="poem.file_type">
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="poem.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <button type="button"
                  class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                  v-on:click="createPoem">
            Thêm mới
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageSong, changePageTitle} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminPoemAdd",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      poem: {
        title: "",
        content: "",
        subtitle: "",
        year: "",
        record_year: "",
        file_id: null,
        file_type: "audio",
        image_id: null,
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      poets: [],
      artists: []
    }
  },
  methods: {
    createPoem() {
      if (!this.poem.title) {
        this.$toast.error("Bạn chưa nhập tên tiếng thơ");
        return;
      }

      if (!this.poem.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($title: String!, $content: String, $subtitle: String, $year: String, $record_year: String, $file_type: String, $file_id: ID, $image_id: ID, $uploader_id: ID, $sync_poem_poet: [SyncPoemPoet], $sync_poem_artist: [SyncPoemArtist]) {
        createPoem(input: {
          title: $title,
          content: $content
          subtitle: $subtitle
          year: $year
          record_year: $record_year
          file_type: $file_type
          file_id: $file_id
          image_id: $image_id
          uploader_id: $uploader_id
          poets: {
            sync: $sync_poem_poet
          }
          artists: {
            sync: $sync_poem_artist
          }
        }) {
          id
        }
      }`;

      let sync_poem_poet = [];
      let sync_poem_artist = [];

      this.poets.map((item, index) => {
        sync_poem_poet.push({id: item.id, order: index});
      });
      this.artists.map((item, index) => {
        sync_poem_artist.push({id: item.id, order: index});
      });

      ApiService.graphql(query, {
        title: this.poem.title,
        content: this.poem.content,
        subtitle: this.poem.subtitle,
        year: this.poem.year,
        record_year: this.poem.record_year,
        file_type: this.poem.file_type,
        file_id: this.poem.file_id,
        image_id: this.poem.image_id,
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_poem_poet: sync_poem_poet,
        sync_poem_artist: sync_poem_artist
      })
          .then(({data}) => {
            if (data.data && data.data.createPoem) {
              this.$toast.success("Cập nhật tiếng thơ thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchPoets(q) {
      let query = `query($q: Mixed) {
        poets(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.poets && data.data.poets.data) {
              return data.data.poets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    handleUploadDone(data) {
      if (data && data.type) {
        this.poem.file_type = data.type;
        this.poem.file.type = data.type;
      }
    }
  },
  computed: {
    canManageSong() {
      return canManageSong();
    }
  },
  mounted() {
    if (!canManageSong()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Thêm tiếng thơ");
  },
}
</script>
